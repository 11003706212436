@import "../../Assets/Styles/variables.module.scss";

.section1 {
  .leftBox {
    .aboutImg1 {
      width: 100%;
      height: auto;
    }
    .socialBox {
      margin-top: 63px;
    }

    .detailedBox {
      margin-top: 63px;
      background-color: $dark_purple;
      padding: 35px 20px;

      .heading {
        font-size: 24px;
        font-family: "PoppinsBold";
        color: $white;
        text-align: center;
      }

      .description {
        margin-top: 20px;
        font-size: 13px;
        font-family: "PoppinsLight";
        color: $white;
      }
    }
  }

  .rightBox {
    .aboutImg2 {
      width: 100%;
      height: auto;
    }

    .detailedBox {
      margin-bottom: 30px;
      background-color: $dark_purple;
      padding: 60px 20px;

      .heading {
        font-size: 24px;
        font-family: "PoppinsBold";
        color: $white;
        text-align: center;
      }

      .description {
        margin-top: 20px;
        font-size: 13px;
        font-family: "PoppinsLight";
        color: $white;
      }
    }

    .infoBox {
      margin-bottom: 40px;
      background-color: $white;
      padding: 20px;
      text-align: center;
      border-left: 0.5px solid gray;
      border-right: 0.5px solid gray;
      border-bottom: 0.5px solid gray;

      .heading {
        font-size: 24px;
        font-family: "PoppinsBold";
        color: $black;
      }

      .description {
        font-size: 14px;
        font-family: "PoppinsLight";
        color: $black;
        text-align: justify;
      }
    }
  }
}

.section2 {
  margin-top: 42px;
  .left {
    text-align: left;

    .heading {
      font-size: 24px;
      font-family: "PoppinsBold";
      color: $black;
    }
  }

  .right {
    .title {
      font-size: 20px;
      font-family: "PoppinsBold";
      color: $black;
    }

    .description {
      text-align: justify;
      font-size: 20px;
      font-family: "PoppinsLight";
      color: $black;
      hyphens: auto;
      word-spacing: -0.5px;
    }
  }
}

.section3 {
  margin-top: 40px;

  .aboutImg3 {
    width: 544px;
    height: auto;
    border-radius: 20px;
  }

  .rightBox {
    .title {
      text-align: center;
      font-size: 24px;
      font-family: "PoppinsBold";
      color: $black;
    }

    .description {
      text-align: justify;
      font-size: 20px;
      font-family: "PoppinsLight";
      color: $black;
    }
  }

  .secondleft {
    margin-top: 40px;
    .secAbout4 {
      width: 544px;
      height: auto;
      margin-bottom: 57px;
      border-radius: 20px;
    }

    .description {
      font-size: 20px;
      font-family: "PoppinsLight";
      color: $black;
      text-align: justify;
      hyphens: auto;
      word-spacing: -0.5px;
    }
  }

  .secondright {
    background-color: $dark_purple;
    padding: 80px 20px;
    .heading {
      font-size: 48px;
      font-family: "PoppinsBold";
      color: $white;
    }

    .description {
      margin-top: 20px;
      font-size: 15px;
      font-family: "PoppinsLight";
      color: $white;
      text-align: justify;
    }
  }
}

.section4 {
  margin-top: 31px;
  margin-bottom: 54px;

  .aboutImg6 {
    width: 100%;
    height: auto;
  }

  .detailBox {
    width: 557px;

    .heading {
      font-size: 32px;
      font-family: "PoppinsBold";
      color: $black;
      text-align: center;
    }

    .description {
      font-size: 20px;
      font-family: "PoppinsLight";
      color: $black;
      text-align: justify;
      hyphens: auto;
      word-spacing: -0.5px;
    }

    .btnBox {
      margin-top: 20px;
      text-align: center;
    }
  }
}

@media screen and (max-device-width: 1024px) {
  .section1 {
    .rightBox {
      .detailedBox {
        margin-top: 30px;
      }
    }
  }
  .section2 {
    .left {
      text-align: center;
      margin-bottom: 30px;
      .heading {
        font-size: 20px;
      }
    }
    .right {
      .title {
        font-size: 20px;
      }
      .description {
        font-size: 14px;
      }
    }
  }

  .section3 {
    .aboutImg3 {
      width: 100%;
    }
    .rightBox {
      .title {
        margin-top: 20px;
        font-size: 20px;
      }
      .description {
        font-size: 14px;
      }
    }

    .secondleft {
      .secAbout4 {
        width: 100%;
        margin-bottom: 30px;
      }
      .description {
        font-size: 14px;
      }
    }

    .secondright {
      margin-top: 30px;
      .heading {
        font-size: 24px;
      }
    }
  }

  .section4 {
    .detailBox {
      margin-top: 20px;
      width: 100%;

      .heading {
        font-size: 24px;
      }

      .description {
        font-size: 14px;
      }
    }
  }
}
