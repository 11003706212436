@import "../../Assets/Styles/variables.module.scss";

.section1 {
  margin-top: 147px;
  margin-bottom: 159px;

  .left {
    .heading {
      font-size: 36px;
      font-family: "PoppinsBold";
      color: $black;
    }

    .description {
      margin-top: 50px;
      font-size: 24px;
      font-family: "PoppinsLight";
      color: $black;
      text-align: justify;
      hyphens: auto;
      word-spacing: -0.5px;
    }
  }

  .right {
    img {
      width: 100%;
      height: auto;
      border-radius: 10px;
    }
  }
}

.section2 {
  .serviceDetailBox {
    margin-bottom: 95px;
    box-shadow: -3px 4px 18px -5px rgba(0, 0, 0, 0.74);
    padding: 30px;

    .detailImage {
      width: 100%;
      height: auto;
      border-radius: 10px;
    }

    .heading {
      font-size: 20px;
      font-family: "PoppinsBold";
      color: $black;
    }

    .description {
      font-size: 16px;
      font-family: "PoppinsLight";
      color: $black;
    }
  }
}

@media screen and (max-width: 1024px) {
  .section1 {
    .left {
      .heading {
        font-size: 24px;
      }
      .description {
        font-size: 18px;
      }
      .subHeading {
        font-size: 20px;
      }
      .description2 {
        font-size: 18px;
      }
    }
  }
  .section2 {
    .serviceDetailBox {
      .detailImage {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
}
