@import "../../Assets/Styles/variables.module.scss";

.blogListBox {
  margin-top: 113px;

  .heading {
    font-size: 20px;
    font-family: "PoppinsBold";
    color: $black;
  }

  .blogAdBox {
    margin-top: 23px;

    .longBlog {
      margin-bottom: 56px;
      .blogImage {
        width: 260px;
        height: 200px;
        border-radius: 10px;
      }

      .title {
        font-size: 16px;
        font-family: $poppins_bold;
        font-weight: bold;
        color: $black;
        margin-top: 10px;
        margin-bottom: 0;
      }

      .description {
        width: 260px;
        font-size: 14px;
        font-family: $poppins_regular;
        font-weight: 400;
        color: $silver;
        margin-top: 5px;
      }

      .userInfo {
        .username {
          font-size: 14px;
          font-family: $poppins_regular;
          font-weight: 400;
          color: $black;
          padding-left: 10px;
        }

        .userdate {
          font-size: 14px;
          font-family: $poppins_regular;
          font-weight: 400;
          color: $black;
          padding-left: 10px;
        }
      }
    }
  }

  .blogBtn {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .message {
      font-size: 16px;
      font-family: 'PoppinsBold';
      color: $black;
    }
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 1024px) {
  .blogListBox {
    .blogAdBox {
      .longBlog {
        .blogImage {
          width: 100%;
          height: auto;
        }

        .description {
          width: 100%;
        }
      }
    }
  }
}