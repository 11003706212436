@import "../../Assets/Styles/variables.module.scss";

.linkBtn {
  display: inline-block;
  width: 180px;
  background-color: $yellow;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-family: 'PoppinsBold';
  font-weight: bold;
  line-height: 24px;
  color: $white;
  padding: 8px;
  text-decoration: none;
  transition: all 0.5s;

  &:hover {
    background-color: $dark_purple;
    color: $white;
  }
}

.normalBtnYellow {
  cursor: pointer;
  width: 180px;
  background-color: $yellow;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-family: 'PoppinsBold';
  font-weight: bold;
  line-height: 24px;
  color: $white;
  padding: 8px;
  transition: all 0.5s;

  &:hover {
    background-color: $dark_purple;
    color: $white;
  }
}

.normalBtnPurple {
    cursor: pointer;
    width: 180px;
    background-color: $dark_purple;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    font-family: 'PoppinsBold';
    font-weight: bold;
    line-height: 24px;
    color: $white;
    padding: 8px;
    transition: all 0.5s;
  
    &:hover {
      background-color: $yellow;
      color: $white;
    }
}

@media screen and (max-width: 1024px) {
  .linkBtn {
    width: 120px;
    font-size: 11px;
    padding: 4px;
  }
}
