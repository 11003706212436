@import "../../Assets/Styles/variables.module.scss";

.outer_landing_page {
  box-shadow: 0 4px 2px -2px gray;
  
  .desktopView {
    .center {
      .menuItems {
        display: flex;
        justify-content: flex-start;
        align-items: center;
  
        .menuItemOpt {
          .servicesDDL {
            button {
              background-color: transparent !important;
              border: none !important;
              font-size: 16px !important;
              font-family: $poppins_bold !important;
              font-weight: bold !important;
              line-height: 24px !important;
              color: $black !important;
            }
          }
        }
  
        .menuItem {
          a {
            padding: 0 15px;
            font-size: 16px;
            font-family: 'PoppinsRegular';
            line-height: 24px;
            color: $black;
            text-decoration: none;
            transition: all 0.5s;
  
            &:hover {
              color: $yellow;
            }
          }
        }
      }
    }
  
    .right {
      text-align: right;
    }
  }
}

// Mobile Menu
.mobileView {
  box-shadow: 0 4px 2px -2px gray;
  .left {
    padding-left: 0;

    a {
      img {
        width: 101px;
        height: 101px;
      }
    }
  }
  .right {
    text-align: right;
  }
}
.menuOptions {
  position: relative;
  z-index: 9999;
  background-color: $white;
  width: 100%;
  height: 100vh;
  padding-top: 30px;

  .menuItemOpt {
    cursor: pointer;
    margin-bottom: 40px;
    .ddlmenu {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .menuItempt {
        padding: 0 15px;
        font-size: 20px;
        font-family: $poppins_regular;
        font-weight: 400;
        line-height: 24px;
        color: $black;
      }
    }

    .menuDDL {
      ul {
        padding-left: 15px;
        list-style: none;
        border-bottom: 1px solid #ccc;

        li {
          margin-top: 10px;
          padding-top: 15px;
          padding-bottom: 15px;
          a {
            font-size: 18px;
            font-family: $poppins_regular;
            font-weight: 400;
            color: $black;
            text-decoration: none;
          }
        }
      }
    }
  }

  .menuItem {
    margin-bottom: 40px;
    a {
      padding: 0 15px;
      font-size: 20px;
      font-family: $poppins_regular;
      font-weight: 400;
      line-height: 24px;
      color: $black;
      text-decoration: none;

      &:hover {
        font-weight: bold;
      }
    }
  }
}
