@import "../../Assets/Styles/variables.module.scss";

.careerForm {
  margin-top: 24px;
  margin-bottom: 50px;

  .heading {
    font-size: 40px;
    font-family: "PoppinsBold";
    line-height: 44px;
    color: $black;
    text-align: center;
  }

  .subtext {
    margin-top: 50px;
    font-size: 24px;
    font-family: "PoppinsLight";
    line-height: 32px;
    color: $black;
    text-align: justify;
    hyphens: auto;
    word-spacing: -0.5px;
  }

  .formItems {
    margin-top: 107px;

    .inputHeading {
      font-size: 24px;
      font-family: "PoppinsBold";
      line-height: 27px;
      color: $black;
      margin-top: 21px;
      margin-bottom: 20px;
    }

    .inputSubHeading {
      font-size: 20px;
      font-family: "PoppinsLight";
      line-height: 27px;
      color: $black;
      margin-top: 20px;

      span {
        color: red;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .careerForm {
    .heading {
      font-size: 18px;
      line-height: 24px;
    }

    .subtext {
      font-size: 18px;
      line-height: 24px;
    }

    .formItems {
      margin-top: 60px;

      .inputHeading {
        font-size: 18px;
        line-height: 24px;
      }

      .inputSubHeading {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}