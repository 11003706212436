@import '../../Assets/Styles/variables.module.scss';

.headerWithText {
  .breadCrumbBox {
    margin-top: 20px;
    .breadCrumb {
      font-size: 14px;
      font-family: 'PoppinsRegular';
      line-height: 20px;
      color: $black;
    }
  }

  .mainTitle {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    margin: 116px 0;

    .mainHeading {
      width: 350px;
      font-size: 36px;
      font-family: 'PoppinsBold';
      line-height: 42px;
      color: $black;
    }
  }
}

@media screen and (max-width: 1024px) {
  .headerWithText {
    .mainTitle {
      .mainHeading {
        font-size: 24px;
      }
    }
  }
}