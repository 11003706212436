@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

// Fonts

$poppins_bold: 'PoppinsBold';
$poppins_regular: 'PoppinsRegular';
$poppins_light: 'PoppinsLight';


// Colors

$black: #000;
$yellow: #FFAF12;
$gray: #c2c2c2;
$white: #fff;
$silver: #919191;
$light_purple: #664984;
$dark_purple: #290251;
