@import "../../Assets/Styles/variables.module.scss";

.formBox {
  margin-top: 106px;

  .formButton {
    margin-top: 115px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
