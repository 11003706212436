@import "../../Assets/Styles/variables.module.scss";

.consultancyForm {
  margin-top: 93px;
  margin-bottom: 93px;
  
  .heading {
    font-size: 24px;
    font-family: 'PoppinsBold';
    color: $black;
    margin-top: 55px;
  }

  .subheading {
    margin-top: 30px;
    font-size: 24px;
    font-family: 'PoppinsLight';
    color: $black;
  }

  .rows_inputs {
    margin-top: 22px;
  }

  .btnMain {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 1024px) {
  .consultancyForm {
    .heading {
      font-size: 18px;
    }
    .subheading {
      font-size: 18px;
    }
  }
}