@import "../../Assets/Styles/variables.module.scss";

.headerMainImage {
  background-size: cover;
  background-position: 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  padding: 10px 0 40px 0;

  .breadCrumbBox {
    .breadCrumb {
      font-size: 14px;
      font-family: "PoppinsRegular";
      line-height: 20px;
      color: $white;
    }
  }

  .heading {
    margin-top: 450px;
    font-size: 40px;
    font-family: "PoppinsBold";
    color: $white;
  }

  .subheading {
    font-size: 40px;
    font-family: "PoppinsBold";
    color: $white;
  }

  .subheadingText {
    font-size: 24px;
    font-size: "PoppinsLight";
    color: $white;
  }
}

.headerMainNormalImage {
  position: relative;
  padding: 0 0 40px 0;

  .headerBanner {
    .desktopBanner {
      width: 100%;
      height: auto;
    }
    .mobileBanner {
      width: 100%;
      height: auto;
    }
  }

  .breadCrumbBox {
    position: absolute;
    top: 20px;
    .breadCrumb {
      font-size: 14px;
      font-family: "PoppinsRegular";
      line-height: 20px;
      color: $white;
    }
  }

  .heading {
    font-size: 40px;
    font-family: "PoppinsBold";
    color: $white;
  }

  .subheading {
    font-size: 40px;
    font-family: "PoppinsBold";
    color: $white;
  }

  .subheadingText {
    font-size: 24px;
    font-size: "PoppinsLight";
    color: $white;
  }
}

@media screen and (max-device-width: 1024px) {
  .headerMainImage {
    background-position: center center;
    .heading {
      font-size: 24px;
    }
    .subheading {
      font-size: 24px;
    }
  }

  .headerMainNormalImage {
    .heading {
      font-size: 24px;
    }

    .subheading {
      font-size: 24px;
    }
  }
}
