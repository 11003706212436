@import '../../Assets/Styles/variables.module.scss';

.container {
  margin-top: 34px;
  margin-bottom: 60px;

  img {
    border-radius: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    margin-bottom: 30px;

    img {
      height: 170px;
    }
  }
}