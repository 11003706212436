@import "../../Assets/Styles/variables.module.scss";

.blogDetailBox {
  margin-bottom: 90px;
  .blogInnerHeading {
    margin-bottom: 30px;

    .userInfo {
      .userText {
        cursor: pointer;
        margin-left: 10px;
        font-size: 20px;
        font-family: "PoppinsLight";
        color: $black;
        transition: all 0.5s;

        &:hover {
          color: $yellow;
        }
      }
    }

    .postDate {
      display: block;
      text-align: right;
      font-size: 20px;
      font-family: "PoppinsLight";
      color: $black;
    }
  }

  .blogHeading {
    margin-top: 40px;
    font-size: 32px;
    font-family: "PoppinsBold";
    color: $black;
  }

  .blogBanner {
    margin-top: 80px;

    img {
      border-radius: 15px;
      width: 100%;
      height: auto;
    }
  }

  .blogDescription {
    margin-top: 60px;
    font-size: 24px;
    font-family: "PoppinsLight";
    color: $black;
    text-align: justify;
    hyphens: auto;
    word-spacing: -0.5px;
  }

  .sideBlogBox {
    margin-top: 50px;
    .image {
      width: 156px;
      height: 144px;
    }

    .heading {
      margin-left: 25px;
      font-size: 15px;
      font-family: "PoppinsBold";
      color: $black;
    }

    .description {
      margin-left: 25px;
      font-size: 14px;
      font-family: "PoppinsLight";
      color: $silver;
    }

    .userBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 25px;

      .username {
        margin-left: 10px;
        font-size: 15px;
        font-family: "PoppinsLight";
        color: $black;
      }

      .userDate {
        font-size: 15px;
        font-family: "PoppinsLight";
        color: $black;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .blogDetailBox {
    .blogInnerHeading {
     .postDate {
      text-align: left;
     }
    }

    .blogHeading {
      font-size: 24px;
    }

    .blogDescription {
      font-size: 18px;
      word-spacing: -2px;
    }

    .sideBlogBox {
      .heading {
        margin-left: 0;
        margin-top: 10px;
      }

      .description {
        margin-left: 0;
      }

      .userBox {
        margin-left: 0;
      }
    }
  }
}
