@import "../../Assets/Styles/variables.module.scss";

.Container {
  background-color: $dark_purple;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 20px 20px 70px 20px;

  .footer_top {
    width: 100%;
    text-align: right;

    a {
      text-decoration: none;
      font-size: 16px;
      font-family: $poppins_regular;
      font-weight: 400;
      color: $white;
    }
  }

  .footer_middle {
    margin-top: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid #656565;
    .slogan {
      font-size: 16px;
      font-family: "PoppinsBold";
      color: $white;
    }

    .footer_links {
      list-style: none;

      li {
        cursor: pointer;
        margin-bottom: 5px;
        text-decoration: none;
        font-size: 16px;
        font-family: $poppins_regular;
        font-weight: 400;
        color: $white;
        transition: all 0.5s;

        &:hover {
          color: $yellow;
        }

        a {
          text-decoration: none;
          font-size: 16px;
          font-family: $poppins_regular;
          font-weight: 400;
          color: $white;
          transition: all 0.5s;

          &:hover {
            color: $yellow;
          }
        }
      }
    }
  }

  .footer_bottom {
    text-align: center;
    margin-top: 20px;

    .footerSocial {
      .socailIco2 {
        margin-left: 30px;
      }
    }
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 1024px) {
  .Container {
    .footer_middle {
      .slogan {
        margin-bottom: 50px;
      }
      .footer_links {
        padding-left: 0;
        margin-bottom: 50px;
      }
    }
  }
}
