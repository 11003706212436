@import "../../Assets/Styles/variables.module.scss";

.contactFormBox {
  margin-top: 40px;

  .contactInfo {
    width: 426px;

    .heading {
      font-size: 24px;
      font-family: "PoppinsBold";
      color: $black;
    }

    .description {
      margin-top: 50px;
      text-align: justify;
      hyphens: auto;
      word-spacing: -0.1px;
      font-size: 20px;
      font-family: "PoppinsLight";
      color: $black;
    }
  }

  .contactBox {
    background-color: $yellow;
    padding: 80px 50px;
    border-radius: 20px;

    .heading {
      text-align: center;
      font-size: 32px;
      font-family: "PoppinsBold";
      color: $white;
    }

    .inputBox {
      border: none;
      outline: 0;
      background-color: transparent;
      border-bottom: 2px solid #fff;
      width: 100%;
      padding: 10px;
      margin-bottom: 50px;
      font-family: "PoppinsRegular";
      color: $white;

      &::placeholder {
        color: $white;
        font-family: "PoppinsRegular";
      }
    }

    .textarea {
      border: none;
      outline: 0;
      background-color: transparent;
      border-bottom: 2px solid #fff;
      width: 100%;
      padding: 10px;
      margin-bottom: 20px;
      font-family: "PoppinsRegular";
      color: $white;

      &::placeholder {
        color: $white;
      }
    }
  }

  .contactBottom {
    margin-top: 271px;
    margin-bottom: 28px;

    .contactDetailBox {
      .heading {
        font-size: 24px;
        font-family: 'PoppinsBold';
        color: $black;
        margin-bottom: 0;
      }

      .description {
        font-size: 16px;
        font-family: 'PoppinsLight';
        color: $black;
      }
    }
  }
}

@media screen and (max-device-width: 1024px) {
  .contactFormBox {
    .contactInfo {
      width: 100%;
      .heading {
        font-size: 18px;
      }

      .description {
        font-size: 16px;
      }
    }

    .contactBox {
      margin-top: 50px;
      .heading {
        font-size: 24px;
      }
    }

    .contactBottom {
      .contactDetailBox {
        .heading {
          font-size: 18px;
        }
      }
    }
  }
}
