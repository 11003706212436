@import "../../Assets/Styles/variables.module.scss";

.requestFormBox {
  margin-top: 187px;
  margin-bottom: 97px;

  .heading {
    margin-top: 20px;
    font-size: 24px;
    font-family: "PoppinsBold";
    color: $black;

    span {
      color: red;
    }
  }

  .formBtn {
    width: 100%;
    margin-top: 98px;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-device-width: 1024px) {
  .requestFormBox {
    .heading {
      font-size: 20px;
    }

    .radioLabel {
      font-size: 18px;
    }
  }
}