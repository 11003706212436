@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"),
    url("./Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
    url("./Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "PoppinsLight";
  src: local("PoppinsLight"),
    url("./Poppins-Light.ttf") format("truetype");
  font-weight: 300;
}