@import '../../Assets/Styles/variables.module.scss';

.section1security {
  margin-top: 86px;
  margin-bottom: 212px;
  width: 967px;

  .heading {
    text-align: center;
    font-size: 32px;
    font-family: 'PoppinsBold';
    color: $black;
  }

  .description {
    margin-top: 43px;
    font-size: 24px;
    font-family: 'PoppinsLight';
    color: $black;
    text-align: justify;
    hyphens: auto;
    word-spacing: -0.5px;
  }

  .listOptions {
    margin-top: 125px;
    margin-left: 90px;
    
    .listMain {
      display: flex;
      justify-content: flex-start;
      align-items: self-start;
  
      .listPoint {
        font-size: 24px;
        font-family: 'PoppinsLight';
        color: $black;
        padding-left: 10px;
      }
    }
  }

  .securityForm {
    margin-top: 81px;
    .heading {
      margin-top: 50px;
      font-size: 32px;
      font-family: 'PoppinsBold';
      color: $black;
    }
  }
}

@media screen and (max-width: 1024px) {
  .section1security {
    width: 100%;

    .heading {
      font-size: 24px;
    }

    .description {
      font-size: 18px;
    }

    .listOptions {
      margin-left: 0;
      
      .listMain {
        .listPoint {
          font-size: 18px;
        }
      }
    }

    .securityForm {
      .heading {
        font-size: 24px;
      }
    }
  }
}