@import "../../Assets/Styles/variables.module.scss";

.inputBoxLabel {
  font-size: 20px;
  font-family: "PoppinsLight";
  color: $black;
  margin-top: 20px;
  margin-bottom: 10px;

  span {
    color: red;
  }
}

.inputBox {
  width: 100%;
  border: 1.5px solid $yellow;
  border-radius: 13px;
  padding: 15px;
  font-size: 16px;
  font-family: "PoppinsLight";
  color: $black;
}

.textAreaLabel {
  font-size: 20px;
  font-family: "PoppinsLight";
  color: $black;
  margin-top: 20px;
  margin-bottom: 10px;

  span {
    color: red;
  }
}

.textarea {
  width: 100%;
  border: 1.5px solid $yellow;
  border-radius: 13px;
  padding: 15px;
  font-size: 16px;
  font-family: "PoppinsLight";
  color: $black;
}

.radioInputBox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-size: 18px;
  font-family: "PoppinsLight";
  color: $black;

  .radioInput {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark::after {
      display: block;
    }
  }

  &:hover {
    .radioInput ~ .checkmark {
      background-color: #ccc;
    }
  }

  .radioInput {
    &:checked ~ .checkmark {
      background-color: $yellow;
    }
  }

  .checkmark {
    &::after {
      left: 10px;
      top: 7px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;

  &::after {
    content: "";
    position: absolute;
    display: none;
  }
}

.DDLBoxLabel {
  font-size: 24px;
  font-family: "PoppinsBold";
  color: $black;
  margin-bottom: 10px;

  span {
    color: red;
  }
}

.selectBox {
  width: 100%;
  padding: 10px;
  background-color: transparent;
  border: 1.5px solid $yellow;
  border-radius: 13px;
  font-size: 16px;
  font-family: "PoppinsLight";
  color: $black;
}


@media screen and (max-width: 1024px) {
  .inputBoxLabel {
    font-size: 16px;
  }
  .textAreaLabel {
    font-size: 16px;
  }
  .DDLBoxLabel {
    font-size: 16px;
  }
  .radioInputBox {
    font-size: 16px;
  }
}