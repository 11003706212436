@import "../../Assets/Styles/variables.module.scss";

// Services
.cardMain {
  cursor: pointer;
  background-color: $white;
  border-radius: 20px;
  padding: 35px 20px 0 20px;
  position: relative;
  height: 435px;
  z-index: 9999;
  top: 0;
  transition: top ease-in-out 0.5s;

  &:hover {
    top: -220px;
    background-color: $white;

    .description {
      color: $white;
    }
  }

  .cardInnerBox {
    margin: 10px 0 0 0;
    background-color: $dark_purple;
    height: 410px;
    border-radius: 30px;
    padding: 55px;
    text-align: center;

    .title {
      font-size: 24px;
      font-family: $poppins_bold;
      font-weight: bold;
      line-height: 30px;
      color: $white;
      margin-top: 20px;
    }

    .description {
      font-size: 16px;
      font-family: $poppins_regular;
      font-weight: 500;
      line-height: 20px;
      color: $gray;
      margin-top: 20px;
    }
  }
}

// Locations
.locationsContainer {
  margin-top: 93px;

  .heading {
    text-align: center;
    font-size: 24px;
    font-family: "PoppinsBold";
    line-height: 30px;
    color: $black;
  }

  .mapImg {
    margin-top: 24px;

    img {
      border-radius: 20px;
      width: 524px;
      height: 483px;
    }
  }

  .mapsList {
    margin-top: 24px;
    ul {
      list-style-type: none;

      li {
        margin-bottom: 32px;

        .locationtext {
          display: flex;
          justify-content: start;
          align-items: center;

          span {
            margin-left: 10px;
            font-size: 24px;
            font-family: "PoppinsBold";
            line-height: 30px;
            color: $black;
          }
        }
      }
    }
  }
}

// Mission
.missionContainer {
  .missionHeader {
    cursor: pointer;
    margin: 91px 0;
    padding: 20px;
    background: linear-gradient(
      144deg,
      rgba(41, 2, 81, 1) 0%,
      rgba(80, 40, 92, 1) 67%,
      rgba(255, 175, 18, 1) 100%
    );
    border-radius: 30px;
    text-align: center;
    font-size: 24px;
    font-family: $poppins_bold;
    font-weight: bold;
    line-height: 54px;
    color: $white;
    transition: all 0.5s;

    &:hover {
      color: $yellow;
    }
  }

  .missionMain {
    margin: 68px 0;

    .missionDesc1 {
      margin-top: 31px;
      width: 401px;
      text-align: center;

      .heading {
        color: $black;
        font-size: 24px;
        font-family: $poppins_bold;
        font-weight: bold;
        line-height: 36px;
      }

      .description {
        color: $black;
        text-align: justify;
        font-size: 20px;
        font-family: $poppins_light;
        font-weight: 300;
        line-height: 30px;
        margin-bottom: 20px;
        hyphens: auto;
        word-spacing: -0.5px;
      }
    }

    .missionImage1 {
      text-align: right;

      img {
        border-radius: 20px;
      }
    }

    .missionImage2 {
      text-align: left;
    }

    .missionDesc2Box {
      display: flex;
      justify-content: end;

      .missionDesc2 {
        margin-top: 31px;
        width: 401px;
        text-align: center;

        .heading {
          color: $black;
          font-size: 24px;
          font-family: $poppins_bold;
          font-weight: bold;
          line-height: 36px;
        }

        .description {
          color: $black;
          text-align: justify;
          font-size: 20px;
          font-family: $poppins_light;
          font-weight: 300;
          line-height: 30px;
          margin-bottom: 20px;
          hyphens: auto;
          word-spacing: -0.5px;
        }
      }
    }
  }

  .missionExclusive {
    background-image: url("../../Assets/Images/mission_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 132px 0 0;
    padding: 50px 50px;
    border-radius: 30px;

    .description {
      .heading {
        font-size: 40px;
        font-family: $poppins_bold;
        font-weight: bold;
        color: $white;
      }

      .content {
        width: 630px;
        font-size: 24px;
        font-family: $poppins_regular;
        font-weight: 400;
        color: $white;
        text-align: justify;
        hyphens: auto;
        word-spacing: -0.5px;
      }
    }

    .buttonContact {
      text-align: center;
    }
  }
}

// Home Blogs
.blogsContainer {
  margin-top: 80px;
  .blogsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .heading {
      font-size: 24px;
      font-family: $poppins_bold;
      font-weight: bold;
      color: $black;
    }

    .viewall {
      font-size: 24px;
      font-family: $poppins_regular;
      font-weight: 400;
      color: $black;
      text-decoration: none;

      &:hover {
        color: $dark_purple;
      }
    }
  }

  .blogAds {
    background-color: $dark_purple;
    padding: 55px 25px 10px 20px;

    .blogAdDetail {
      margin-bottom: 100px;

      .heading {
        font-size: 16px;
        font-family: $poppins_bold;
        font-weight: bold;
        color: $white;
      }

      .userDetail {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .userInfo {
          .postname {
            font-size: 16px;
            font-family: $poppins_light;
            font-weight: 300;
            color: $white;
            padding-left: 10px;
            margin-bottom: 0;
          }
          .postdate {
            font-size: 16px;
            font-family: $poppins_light;
            font-weight: 300;
            color: $white;
            padding-left: 10px;
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }

      .postdescription {
        font-size: 16px;
        font-family: $poppins_light;
        font-weight: 300;
        color: $white;
        margin-top: 10px;
      }
    }
  }

  .longBlog {
    margin-bottom: 20px;
    .blogImage {
      width: 260px;
      height: 200px;
      border-radius: 20px;
    }
    .title {
      font-size: 16px;
      font-family: $poppins_bold;
      font-weight: bold;
      color: $black;
      margin-top: 10px;
      margin-bottom: 0;
    }
    .description {
      width: 260px;
      font-size: 14px;
      font-family: $poppins_regular;
      font-weight: 400;
      color: $silver;
      margin-top: 5px;
    }
    .userInfo {
      .username {
        font-size: 14px;
        font-family: $poppins_regular;
        font-weight: 400;
        color: $black;
        padding-left: 10px;
      }
      .userdate {
        font-size: 14px;
        font-family: $poppins_regular;
        font-weight: 400;
        color: $black;
        padding-left: 10px;
      }
    }
  }

  .loadmore {
    margin-top: 30px;
    text-align: center;
  }
}

// Industries Serve
.industriesContainer {
  margin-top: 56px;

  .heading {
    font-size: 24px;
    font-family: $poppins_bold;
    font-weight: bold;
    color: $black;
    text-align: center;
    margin-bottom: 50px;
  }

  .box {
    cursor: pointer;
    width: 173px;
    height: 173px;
    background-color: $dark_purple;
    text-align: center;
    padding: 50px 20px;
    border-radius: 20px;
    margin-bottom: 30px;
    transition: all 0.5s;

    .text {
      font-size: 18px;
      font-family: $poppins_bold;
      font-weight: bold;
      color: $white;
    }

    &:hover {
      background-color: $yellow;
    }
  }
}

// Clients
.clientsContainer {
  margin-top: 169px;
  text-align: center;

  .heading {
    font-size: 24px;
    font-family: $poppins_bold;
    font-weight: bold;
    color: $black;
    text-align: center;
    margin-bottom: 50px;
  }

  .icon {
    margin-bottom: 35px;
  }
}

// Our Team
.teamContainer {
  .heading {
    text-align: center;
    font-size: 24px;
    font-family: $poppins_bold;
    font-weight: bold;
    color: $black;
    margin-bottom: 40px;
  }

  .teamBox {
    text-align: center;

    .image {
      border-radius: 30px;
    }
    .name {
      font-size: 20px;
      font-family: $poppins_bold;
      font-weight: bold;
      color: $black;
      margin-top: 10px;
      margin-bottom: 0;
    }
    .position {
      font-size: 16px;
      font-family: $poppins_regular;
      font-weight: 400;
      color: $black;
    }
  }

  .teamDetails {
    margin-top: 165px;
    margin-bottom: 127px;

    .teamImage {
      width: 610px;
    }

    .teamInnerDetails {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .heading {
        width: 100%;
        text-align: center;
        font-size: 24px;
        font-family: $poppins_bold;
        font-weight: bold;
        color: $black;
        margin-bottom: 20px;
      }
      .description {
        width: 440px;
        text-align: justify;
        font-size: 24px;
        font-family: 'PoppinsRegular';
        color: $black;
        hyphens: auto;
        word-spacing: -0.5px;
      }
    }
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 1024px) {
  // Services
  .cardMain {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    height: auto;
    top: 0;

    &:hover {
      top: 0;
    }

    .cardInnerBox {
      height: 170px;
      padding: 15px 10px;
      transition: all 0.5s;

      &:hover {
        top: 0;
      }

      .icon {
        img {
          width: 30px;
        }
      }

      .title {
        font-size: 16px;
        margin-top: 10px;
      }

      .description {
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }

  // Locations
  .locationsContainer {
    .mapImg {
      img {
        width: 100%;
        height: auto;
      }
    }

    .mapsList {
      ul {
        padding-left: 0;

        li {
          .locationtext {
            span {
              font-size: 18px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }

  // Mission
  .missionContainer {
    .missionHeader {
      margin-top: 60px;
      font-size: 18px;
      line-height: 24px;
    }

    .missionMain {
      margin: 15px 0;
      .missionDesc1 {
        width: 100%;

        .description {
          line-height: 25px;
        }
      }

      .missionImage1 {
        margin-top: 40px;

        img {
          width: 100%;
          height: auto;
        }
      }

      .missionImage2 {
        margin-top: 40px;

        img {
          width: 100%;
          height: auto;
        }
      }

      .missionDesc2 {
        width: 100%;

        .description {
          line-height: 25px;
        }
      }
    }

    .missionExclusive {
      margin-top: 100px;
      .description {
        .heading {
          text-align: center;
          font-size: 25px;
        }

        .content {
          width: 100%;
          font-size: 18px;
          word-spacing: -6px;
        }
      }
    }
  }

  // Blogs
  .blogsContainer {
    .blogAds {
      padding: 55px 25px 5px 20px;

      .blogAdDetail {
        margin-bottom: 50px;
      }
    }
    .longBlog {
      margin-top: 20px;

      .blogImage {
        width: 100%;
        height: auto;
      }

      .description {
        width: 100%;
      }
    }
  }

  // Industries Serve
  .industriesContainer {
    .box {
      width: 100%;
      height: auto;
      padding: 25px 10px;

      &:hover {
        background-color: $yellow;
      }
    }
  }

  // Our Team
  .teamContainer {
    .teamDetails {
      .teamImage {
        width: 100%;
        height: auto;
      }
      .teamInnerDetails {
        margin-top: 30px;
        .description {
          width: 100%;
          font-size: 18px;
        }
      }
    }
  }
}
